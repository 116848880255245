<template>
  <div class="modal" @click.self.prevent="$emit('close')">
    <transition name="bounce">
      <div class="modal__body" v-if="show">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from "vue";
const props = defineProps({
  showModalWithOverflow: {
    type: Boolean,
    default: false,
  },
});
const show = ref(false);
const documentElement = ref(null);
const headerElemet = ref(null);
const scrollbarWidth = computed(() => {
  if (window?.innerWidth && document.body?.clientWidth) {
    return `${window?.innerWidth - document.body?.clientWidth}px`;
  }
  return `0px`;
});
onMounted(() => {
  show.value = true;
});
onUnmounted(() => {});

watch(
  props,
  () => {
    if (props?.showModalWithOverflow) {
      show.value = true;
      nextTick(() => {
        documentElement.value = document.body;
        documentElement.value?.style?.setProperty(
          "--scrollbar-width",
          scrollbarWidth.value
        );
        headerElemet.value = document.querySelector(".header-main");
        documentElement.value.style.overflow = "hidden";
        documentElement.value.style.paddingRight = scrollbarWidth.value;
        headerElemet.value.style.paddingRight = scrollbarWidth.value;
      });
    } else {
      if (documentElement.value && headerElemet.value) {
        documentElement.value.style.overflow = "auto";
        documentElement.value.style.paddingRight = "0px";
        headerElemet.value.style.paddingRight = "0px";
      }
    }
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.32);
  z-index: 100;

  &__body {
    width: 288px;
    border-radius: 16px;
    background-color: #fff;
  }
}
</style>
