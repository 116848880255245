<template>
    <div
        v-if="!link"
        class="v-button"
        :class="{
      'v-button_disabled': disabled,
      'v-button_auto-size': autoSize,
      'v-button_text-btn': textBtn,
      'v-button_click-effect': clickEffect
    }"
        :style="{
      backgroundColor: bgBtn,
      color: colBtn,
      border: borderColor ? `1.5px solid ${borderBtn}` : 'none',
    }"
        :disabled="disabled"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
    >
    <span class="v-button__icon" v-if="$slots.iconLeft">
      <slot name="iconLeft"></slot>
    </span>
        {{ label }}
    </div>
    <router-link
        :to="to"
        v-else
        class="v-button"
        :class="{
      'v-button_disabled': disabled,
      'v-button_auto-size': autoSize,
      'v-button_text-btn': textBtn,
    }"
        :style="{
      backgroundColor: bgBtn,
      color: colBtn,
      border: borderColor ? `1px solid ${borderBtn}` : 'none',
    }"
        :disabled="disabled"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
    >
    <span class="v-button__icon" v-if="$slots.iconLeft">
      <slot name="iconLeft"></slot>
    </span>
        {{ label }}
    </router-link>
</template>

<script setup>
import {onActivated, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";

const props = defineProps({
    link: {
        type: Boolean,
        default: false,
    },
    to: {
        type: String
    },
    label: {
        type: String,
        required: true,
    },
    bg: {
        type: String,
        default: "#0086B2",
    },
    bgHover: {
        type: String,
        default: "#1E9AC6",
    },
    color: {
        type: String,
        default: "#FFFFFF",
    },
    colorHover: {
        type: String,
        default: "#FFFFFF",
    },
    disableHover: {
        type: Boolean,
        default: false,
    },
    borderColor: {
        type: String,
    },
    borderColorHover: {
        type: String,
    },
    autoSize: {
        type: Boolean,
        default: false,
    },
    clickEffect: {
        type: Boolean,
        default: false
    },
    textBtn: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },

});

const bgBtn = ref(props.bg);
const colBtn = ref(props.color);
const borderBtn = ref(props.borderColor);

const route = useRoute()

const mouseenter = () => {
    if (props.disableHover) return;
    bgBtn.value = props.bgHover;
    colBtn.value = props.colorHover;
    borderBtn.value = props.borderColorHover
};

const mouseleave = () => {
    if (props.disableHover) return;
    bgBtn.value = props.bg;
    colBtn.value = props.color;
    borderBtn.value = props.borderColor
};

onActivated(() => {
    bgBtn.value = props.bg;
    colBtn.value = props.color;
    borderBtn.value = props.borderColor
})
</script>

<style scoped lang="scss">
.v-button {
    height: 48px;
    padding: 0 7.5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: none;
    text-align: center;
    text-decoration: none;
    transition: 0.3s;
    border: none;
    outline: none;

    &_click-effect:active {
        background-color: #006894 !important;
    }

    &_disabled {
        background-color: #BBC4CD !important;
        cursor: no-drop;
    }

    &_auto-size {
        height: auto;
        padding: 0;
    }

    &_text-btn {
        height: auto;
        padding: 0;
        background-color: transparent !important;
        font-size: 14px;
        font-weight: 400;
    }

    &__icon {
        margin-right: 4px;
        margin-top: 3px;
    }


}
</style>
