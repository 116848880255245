import MainLayout from '../layouts/MainLayout';

const routes = [
    {
        path: '/vacancies',
        meta: { role: 'HR', layout: MainLayout, name: 'Вакансии', type: 'vacancy', pageName: 'Вакансия' },
        children: [
            {
                path: '',
                name: 'Vacancies',
                component: () => import('../views/vacancies'),
            },
            {
                path: 'new',
                name: 'NewVacancies',
                meta: { name: 'Создание вакансии' },
                component: () => import('../views/createVacancy'),
            },
            {
                path: ':id',
                name: 'Vacancy',
                meta: { name: 'Карточка вакансии', role: 'HR' },
                children: [
                    {
                        path: '',
                        name: 'Vacancy',
                        component: () => import('../views/vacancy'),
                    },
                    {
                        path: 'responses',
                        name: 'VacancyResponses',
                        meta: { name: 'Отклики' },
                        component: () => import('../views/responses'),
                    },
                    {
                        path: 'route',
                        name: 'VacancyRoute',
                        meta: { name: 'Маршрут согласования' },
                        component: () => import('../views/coordinationRoute'),
                    },
                    {
                        path: 'edit',
                        name: 'EditVacancy',
                        meta: { name: 'Редактирование вакансии' },
                        component: () => import('../views/createVacancy'),
                    },
                ]
            },
        ]
    },
    {
        path: '/candidates',
        meta: { role: 'HR', layout: MainLayout, name: 'кандидаты', type: 'vacancy', pageName: 'Кандидат' },
        children: [
            {
                path: '',
                name: 'candidates',
                component: () => import('../views/candidates'),
            },
            {
                path: 'new',
                name: 'NewCandidates',
                meta: { name: 'Создание кандидата' },
                component: () => import('../views/createCandidate'),
            },
            {
                path: ':id',
                name: 'Candidate',
                meta: { name: 'Карточка кандидата', role: 'HR' },
                children: [
                    {
                        path: '',
                        name: 'Candidate',
                        component: () => import('../views/candidate'),
                    },
                    {
                        path: 'route',
                        name: 'Route',
                        meta: { name: 'Маршрут согласования' },
                        component: () => import('../views/coordinationRoute'),
                    },
                    {
                        path: 'edit',
                        name: 'EditCandidate',
                        meta: { name: 'Редактирование кандидата' },
                        component: () => import('../views/editCandidate'),
                    },
                ]
            },
        ]
    },
    {
        path: '/extended-questionnaire',
        meta: { role: 'HR', layout: MainLayout, name: 'Анкета', type: 'template', pageName: 'Анкета' },
        component: () => import('../views/extendedQuestionnaire'),
    },
    {
        path: '/templates',
        meta: { role: 'HR', layout: MainLayout, name: 'Шаблоны', type: 'template', pageName: 'Шаблон' },
        children: [
            {
                path: '',
                name: 'Templates',
                component: () => import('../views/templates'),
            },
            {
                path: 'new',
                name: 'NewTemplate',
                meta: { name: 'Новый шаблон' },
                component: () => import('../views/createVacancy'),
            },
            {
                path: ':templateId/create',
                name: 'CreateTemplate',
                meta: { name: 'Новая вакансия' },
                component: () => import('../views/createVacancy'),
            },
            {
                path: ':id',
                name: 'Template',
                meta: { name: 'Карточка шаблона' },
                children: [
                    {
                        path: '',
                        name: 'Template',
                        component: () => import('../views/vacancy'),
                    },
                    {
                        path: 'route',
                        name: 'route',
                        meta: { name: 'Маршрут согласования' },
                        component: () => import('../views/coordinationRoute'),
                    },
                    {
                        path: 'edit',
                        name: 'EditTemplate',
                        meta: { name: 'Редактировать шаблон' },
                        component: () => import('../views/createVacancy'),
                    },
                ]
            }
        ]
    },
    {
        path: '/responses',
        meta: { role: 'HR', layout: MainLayout, name: 'Отклики' },
        children: [
            {
                path: '',
                name: 'ResponsesList',
                component: () => import('../views/responses'),
            },
            {
                path: ':id',
                name: 'ResponseCard',
                meta: { name: 'Карточка отклика' },
                component: () => import('../views/response'),
            }
        ]
    },
]

export default routes
