import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { PDFPlugin } from 'vue3-pdfmake';
// import './plugins/firebase.js'
import './plugins/firebaseApp'
// import './firebase-messaging-sw'
import VueMask from '@devindex/vue-mask';

// if (process.env.NODE_ENV === 'production') {
//   // import('./firebase-messaging-sw')
//   import('./service-worker/messaging_on_background_message')
// }else {
//   // import('../public/firebase-messaging-sw')
// }
const app = createApp(App)
app.use(store).use(router).mount('#app')

import { SetupCalendar } from 'v-calendar';
app.use(SetupCalendar, {})

app.use(PDFPlugin);
app.use(VueMask)

// Components
import ModalWindow from './components/ModalWindow'
app.component('ModalWindow', ModalWindow)

import VButton from './components/UI/VButton'
app.component('VButton', VButton)

import VButtonNew from './components/UI/VButtonNew'
app.component('VButtonNew', VButtonNew)

import VCheckbox from './components/UI/VCheckbox'
app.component('VCheckbox', VCheckbox)

import VCheckboxLabel from './components/UI/VCheckboxLabel'
app.component('VCheckboxLabel', VCheckboxLabel)

import VDefaultInput from './components/UI/VDefaultInput'
app.component('VDefaultInput', VDefaultInput)

import VCustomInput from './components/UI/VCustomInput'
app.component('VCustomInput', VCustomInput)

import VSegmentedControl from './components/UI/VSegmentedControl'
app.component('VSegmentedControl', VSegmentedControl)

import VTooltip from './components/UI/VTooltip'
app.component('VTooltip', VTooltip)

import VNoContent from './components/UI/VNoContent'
app.component('VNoContent', VNoContent)

import VBreadcrumbs from './components/UI/VBreadcrumbs'
app.component('VBreadcrumbs', VBreadcrumbs)

import VTextarea from './components/UI/VTextarea'
app.component('VTextarea', VTextarea)

import VEditor from './components/UI/VEditor'
app.component('VEditor', VEditor)

import VSelect from './components/UI/VSelect'
app.component('VSelect', VSelect)

import VField from './components/UI/VField'
app.component('VField', VField)

import VFile from './components/UI/VFile'
app.component('VFile', VFile)

import VUploadFile from './components/UI/VUploadFile'
app.component('VUploadFile', VUploadFile)

import VFileImage from './components/UI/VFileImage'
app.component('VFileImage', VFileImage)

import VSearchInput from './components/UI/VSearchInput'
app.component('VSearchInput', VSearchInput)

import VSearchField from './components/UI/VSearchField'
app.component('VSearchField', VSearchField)

import VSearchSelect from './components/UI/VSearchSelect'
app.component('VSearchSelect', VSearchSelect)

import VDate from './components/UI/VDate'
app.component('VDate', VDate)

import VChip from './components/UI/VChip'
app.component('VChip', VChip)

import VAccordion from './components/UI/VAccordion'
app.component('VAccordion', VAccordion)

import VTextValidate from './components/UI/VTextValidate'
app.component('VTextValidate', VTextValidate)