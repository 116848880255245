import {createRouter, createWebHistory} from 'vue-router'
import store from "../store";

import EmptyLayout from '../layouts/EmptyLayout';

// Roles
import superAdministrator from './superAdministrator'
import mainHr from './mainHr'
import hr from './hr'
import directionLeader from './directionLeader'
import securituService from './securituService'
import approvingPerson from './approvingPerson.js'


const routes = [
    {
        path: '/',
        redirect: () => {
            return '/authorization'
        },
    },
    {
        path: '/authorization',
        name: 'Authorization',
        meta: {layout: EmptyLayout},
        component: () => import('../views/authorization')
    },
    {
        path: '/agreement',
        name: 'Agreement',
        meta: {layout: EmptyLayout},
        component: () => import('../views/agreement')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: [...routes, ...superAdministrator, ...mainHr, ...hr, ...directionLeader, ...securituService, ...approvingPerson ],
})

router.beforeEach(async (to, from, next) => {

    if (localStorage.token) {
       await store.dispatch("auth/auth", {
          token: localStorage.token,
          role: localStorage.role
        });
    }

    if(to.fullPath === '/authorization') {
       if(store.getters['auth/role'] === 'SUPER_ADMIN') next('/companies')
       if(store.getters['auth/role'] === 'MAIN_HR') next('/employees')
       if(store.getters['auth/role'] === 'HR') next('/vacancies')
       if(store.getters['auth/role'] === 'APPROVING_PERSON') next('/responses')
       if(store.getters['auth/role'] === 'DIRECTION_LEADER') next('/tasks')
       if(store.getters['auth/role'] === 'SECURITY_SERVICE') next('/sb-tasks')
    }

    if (!store.getters['auth/token']) {
        if(to.fullPath === '/agreement') {
            next()
            return
        }
        (to.fullPath !== '/authorization') ? next('/authorization') : next()
    } else if (to.meta.role === store.getters['auth/role']) {
        next()
    }else {
        next()
    }

})

export default router

