<template>
  <div :class="['search-input-select', disabled ? 'search-input-select_disabled' : '']">
    <VCustomInput
     :disabled="disabled"
      :placeholder="placeholder"
      :error="error"
      v-model="inputVal"
      id="region"
      @input="onInput"
      @focus="showItems = true"
      @blur="blur"
      class="search-input-select__inp"
    />

    <p class="search-input-select__inp-error" v-if="error">{{ errorMessage }}</p>

    <div
      class="search-input-select__section-search-items"
      v-if="showItems && items.length"
    >
      <div
        class="search-input-select__section-search-result"
        v-for="item in items"
        :key="item.id"
        :class="{
          'search-input-select__section-search-result_active':
            false === item.id,
        }"
        @click="selectItem(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  modelValue: [String, Number],
  placeholder: {
    type: String,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
  },
  items: {
    type: Array,
    default: [],
  },
  disabled: {
        type: Boolean,
        default: false
    },
});

const emit = defineEmits(["update:modelValue", "blur", "selectItem", "input"]);
const inputVal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const showItems = ref(false);

const itemsList = computed(() => {
  return props.items.filter(
    (item) =>
      item
        ?.toUpperCase()
        .indexOf(inputVal.value ? inputVal.value.toUpperCase() : "") >= 0
  );
});

const onInput = (val) => {
    emit("input", val)
}

const selectItem = (item) => {
    emit("selectItem", item)
}
const blur = () => {
  setTimeout(() => {
    showItems.value = false;
  }, 300);
  setTimeout(() => {
    emit("blur");
  }, 400);
};
</script>

<style scoped lang="scss">
.search-input-select {
  position: relative;

  &_disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  &__inp {
    width: 100%;
    :deep(.v-input__input){
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__items {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    max-height: 300px;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid $light-gray-1;
    box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.12);
    z-index: 2;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__item {
    width: 100%;
    height: 40px;
    padding-left: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    transition: 0.1s;

    &:hover {
      background-color: $light-gray-1;
    }
  }

  &__inp-error {
    margin-top: 4px;
    font-size: 14px;
    color: $red;
  }

  &__section-search-items {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    max-height: 256px;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid $light-gray-1;
    box-shadow: 2px 4px 24px 0 #0000001f;
    z-index: 9;
    overflow-y: auto;
    scrollbar-width: thin;
    max-height: calc(45px * 5);

  }

  &__section-search-result {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 12px 8px 4px;
    display: block;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;


    &:hover {
      background-color: $light-gray-1;
      color: $dark;
    }
  }
}
</style>
