<template>
  <router-view v-slot="{ Component }">
    <component :is="route.meta.layout || 'div'">
      <template v-if="includePages">
        <keep-alive>
          <component :is="Component"/>
        </keep-alive>
      </template>
      <template v-else>
        <component :is="Component"/>
      </template>
    </component>
  </router-view>

  <ModalWindow v-if="showPopupError" @close="showPopupError = false" class="error-modal">
    <ErrorModal @close="showPopupError = false" :title="popupData.title" :description="popupData.description"
                :data="popupData"/>
  </ModalWindow>
  <ModalWindow v-if="showPopupLoader" class="loader-modal">
      <img src="@/assets/images/icons/loader-anim.svg" class="loader-modal__loader" alt="loading">
      <h3 class="loader-modal__text">Загружаем файлы</h3>
  </ModalWindow>
</template>


<script setup>
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import eventBus from "@/services/eventBus";
import ErrorModal from '@/components/ModalWindow/ModalBodyes/ErrorModal'

const route = useRoute()
const popupData = ref({
  title: '',
  description: '',
  loader: false,
})
const includePages = computed(() => {
  const page = route.name
  return page === 'Vacancy' || page === 'Vacancies' ||
      page === 'ResponsesList' || page === 'ResponseCard' ||
      page === 'Templates' || page === 'Template' ||
      page === 'Companies' || page === 'Company' ||
      page === 'DLResponses' || page === 'DLResponse' ||
      page === 'SBResponses' || page === 'SBResponse' || page === 'VacancyResponses' || page === 'EmployeeTasks' || page === 'EmployeeTask'
})

const showPopupError = ref(false)
const showPopupLoader = ref(false)
eventBus.on("errorRequest", (data) => {
  showPopupError.value = true
  if (data?.loader) {
    popupData.value.loader = true
  }
  popupData.value.title = data?.title || "Проверьте подключение к Интернету."
  popupData.value.description = data?.description || "Ошибка"
});


eventBus.on("loaderOn", (data) => {
  showPopupLoader.value = true
});

eventBus.on("loaderOff", (data) => {
  showPopupLoader.value = false
});

</script>

<style lang="scss">
body {
}
</style>

<style scoped lang="scss">

.error-modal {
  &::v-deep(.modal__body) {
    width: 304px;

    .modal-body__description {
      max-width: 300px;
    }
  }
}

.loader-modal {

  &::v-deep(.modal__body) {
    height: 168px;
    width: 352px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  &__loader {
    width: 56px;
    height: 56px;
    transform: scaleX(-1);
  }

  &__text {
    font-size: 20px;
    margin-top: 24px;
  }
}
</style>
