<template>
  <header class="header-main">
    <div class="header-main__wrapper main-container">
      <div class="header-main__menu">
        <router-link to="/">
          <div>
            <div class="header-main__logo">
              <img src="@/assets/images/new-logo-black.svg" alt="logo"/>
            </div>
          </div>
        </router-link>
        <div>
          <nav v-if="role === 'SUPER_ADMIN'" class="header-main__nav">
            <router-link to="/companies" class="header-main__nav-item">
              <p class="header-main__nav-name">Компании</p>
            </router-link>
            <router-link to="/super-admins" class="header-main__nav-item">
              <p class="header-main__nav-name">Администраторы</p>
            </router-link>
            <router-link to="/specialties" class="header-main__nav-item">
              <p class="header-main__nav-name">Специальности</p>
            </router-link>
          </nav>
          <nav v-if="role === 'MAIN_HR'"
               :class="['header-main__nav', role === 'MAIN_HR' ? 'header-main__nav_main-hr' : '']">
            <router-link to="/employees" class="header-main__nav-item">
              <p class="header-main__nav-name">Сотрудники</p>
            </router-link>

            <router-link to="/vacancies" class="header-main__nav-item">
              <p class="header-main__nav-name">Вакансии</p>
            </router-link>
            <router-link to="/responses" class="header-main__nav-item">
              <p class="header-main__nav-name">Задачи</p>
            </router-link>
            <router-link to="/candidates" class="header-main__nav-item">
              <p class="header-main__nav-name">База кандидатов</p>
            </router-link>
            <router-link to="/templates" class="header-main__nav-item">
              <p class="header-main__nav-name">Шаблоны</p>
            </router-link>
            <div
                :class="['header-main__nav-item header-main__nav-item_settings',{'header-main__nav-item_settings-active': showSettingsDropdown || !!($route.path.includes('settings'))}]"
                @click.stop="openSettings">
              <p class="header-main__nav-name">Настройки</p>
              <div class="header-main__settings-dropdown" v-if="showSettingsDropdown">
                <router-link to="/settings" class="header-main__settings-dropdown-item">Настройка сроков</router-link>
                <router-link to="/settings?reasons=true" class="header-main__settings-dropdown-item">Причины отказа
                </router-link>
              </div>
            </div>
          </nav>
          <nav v-if="role === 'HR'" :class="['header-main__nav', role === 'MAIN_HR' ? 'header-main__nav_main-hr' : '']">
            <router-link to="/vacancies" class="header-main__nav-item">
              <p class="header-main__nav-name">Вакансии</p>
            </router-link>
            <router-link to="/responses" class="header-main__nav-item">
              <p class="header-main__nav-name">Задачи</p>
            </router-link>
            <router-link to="/candidates" class="header-main__nav-item">
              <p class="header-main__nav-name">База кандидатов</p>
            </router-link>
            <router-link to="/templates" class="header-main__nav-item">
              <p class="header-main__nav-name">Шаблоны</p>
            </router-link>
          </nav>
          <nav v-if="role === 'APPROVING_PERSON'" class="header-main__nav">
            <router-link to="/responses" class="header-main__nav-item">
              <p class="header-main__nav-name">Задачи</p>
            </router-link>
          </nav>
          <nav v-if="role === 'SECURITY_SERVICE'" class="header-main__nav">
            <router-link to="/sb-tasks" class="header-main__nav-item">
              <p class="header-main__nav-name">Задачи</p>
            </router-link>
          </nav>
        </div>
        <div
          @click="showPersonalDataWindow = true"
          class="header-main__profile-btn"
        >
        
          <div class="header-main__profile-btn-col">
            <div class="header-main__profile-btn-name">
              {{ profileInfo?.full_name?.value }}
            </div>
            <p
              v-if="role === 'SUPER_ADMIN'"
              class="header-main__profile-btn-type"
            >
              Администратор
            </p>
            <p v-if="role === 'HR'" class="header-main__profile-btn-type">
              HR-менеджер
            </p>
            <p v-if="role === 'MAIN_HR'" class="header-main__profile-btn-type">
              Руководитель подбора
            </p>
            <p
              v-if="role === 'SECURITY_SERVICE'"
              class="header-main__profile-btn-type"
            >
              СБ
            </p>
            <p
              v-if="role === 'APPROVING_PERSON'"
              class="header-main__profile-btn-type"
            >
              Согласовант
            </p>
          </div>
        </div>
      </div>
    </div>

    <ModalWindow v-if="showPersonalDataWindow">
      <PersonalData
        :profileInfo="profileInfo"
        @close="showPersonalDataWindow = false"
        @openLogout="openLogoutModal"
        @openChangePasswordModal="openChangePasswordModal"
      />
    </ModalWindow>

    <ModalWindow v-if="showLogoutWindow">
      <LogOut @close="showLogoutWindow = false" @logout="logout" />
    </ModalWindow>

    <ModalWindow v-if="showChangePassword">
      <ChangePassword
        :errorPasswordText="errorPasswordText"
        @close="(showChangePassword = false), (errorPasswordText = '')"
        @change="postChangePassword"
      />
    </ModalWindow>
  </header>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import apiProfile from "@/api/profile";
import ModalWindow from "@/components/ModalWindow/index.vue";
import PersonalData from "@/components/ModalWindow/ModalBodyes/PersonalData";
import LogOut from "@/components/ModalWindow/ModalBodyes/LogOut";
import ChangePassword from "@/components/ModalWindow/ModalBodyes/ChangePassword";

const store = useStore();
const router = useRouter();

const role = store.getters["auth/role"];

const showPersonalDataWindow = ref(false);
const showLogoutWindow = ref(false);
const showChangePassword = ref(false);
const showSettingsDropdown = ref(false)
const logout = async () => {
  await apiProfile.getLogout();
  await store.dispatch("auth/logout");
  setTimeout(() => {
    showLogoutWindow.value = false;
    router.push("/authorization");
  }, 400);
};

const openLogoutModal = () => {
  showPersonalDataWindow.value = false;
  showLogoutWindow.value = true;
};

const openChangePasswordModal = () => {
  showPersonalDataWindow.value = false;
  showChangePassword.value = true;
};

const profileInfo = ref({});
const getProfile = async () => {
  const response = await apiProfile.getProfile();
  profileInfo.value = response;
  await store.dispatch("auth/setProfile", response);
};
const errorPasswordText = ref("");
const postChangePassword = async (body) => {
  errorPasswordText.value = "";
  try {
    const response = await apiProfile.postChangePassword(body);
    await store.dispatch("auth/logout");
    setTimeout(() => {
      showChangePassword.value = false;
      router.push("/authorization");
    }, 400);
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.errors?.current_password.length) {
      errorPasswordText.value = error.response.data.errors.current_password[0];
    } else {
      await store.dispatch("auth/logout");
      setTimeout(() => {
        showChangePassword.value = false;
        router.push("/authorization");
      }, 400);
    }
  } finally {
  }
};

const openSettings = () => {
  showSettingsDropdown.value = !showSettingsDropdown.value
}

const onOuterSettingsClick = function (e) {
  if (!e.target.closest(".header-main__settings-dropdown") && showSettingsDropdown.value) {
    showSettingsDropdown.value = false;
  }
};
onMounted(() => {
  getProfile();
  document.addEventListener("click", onOuterSettingsClick);
});

onUnmounted(() => {
  document.removeEventListener("click", onOuterSettingsClick);
});
</script>

<style scoped lang="scss">
.header-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E4E7EE;
  background-color: #f4f5f7;
  user-select: none;
  z-index: 99;

  &__logo {
    display: flex;
    align-items: center;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin-left: 20px;
  }

  &__nav-item {
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &_settings{
      cursor: pointer;
    }
    &_settings-active{
      cursor: pointer;
      position: relative;
    & > .header-main__nav-item-icon {
      opacity: 1;
      path,
      rect {
        stroke: #fff;
        color: #fff;
      }
    }

    & .header-main__nav-item-chevron {
      transform: rotate(0deg);
    }

    & > .header-main__nav-name {
      opacity: 1;
    }
    }

    &:not(:first-child) {
      //margin-left: 12px;
    }
  }

  &__nav-item-chevron{
    transform: rotate(180deg);
  }

  &__nav-item-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }

  &__nav-name {
    margin-left: 8px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
  }

  .router-link-active {
    position: relative;

    & > .header-main__nav-item-icon {
      opacity: 1;
      path,
      rect {
        stroke: #000;
        color: #000;
      }
    }

    & > .header-main__nav-name {
      opacity: 1;
    }
  }

  &__profile-btn {
    max-width: 160px;
    margin-left: auto;
    cursor: pointer;
  }

  &__profile-btn-name {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__profile-btn-type {
    width: 100px;
    color: #000;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__profile-chat-btn {
    width: 133px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;

    p {
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      span {
        margin-left: 4px;
      }
    }

    img {
      margin-left: 8px;
    }
  }

  &__settings-dropdown {
    width: 152px;
    height: 82px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    top: 40px;
  }

  &__settings-dropdown-item {
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    color: $dark-blue;
    text-decoration: none;
    &:first-child {
      border-bottom: 1px solid $light-gray-1;
    }

  }
}
</style>
