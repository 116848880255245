<template>
    <div
        v-if="!link"
        class="v-button"
        :class="[styleBtn, {
      'v-button_auto-size': autoSize,
      'v-button_text-btn': textBtn,
    }]"
        :style="{
      border: borderColor ? `1.5px solid ${borderBtn}` : 'none',
    }"
        :disabled="disabled"
    >
    <span class="v-button__icon" v-if="$slots.iconLeft && !loading">
      <slot name="iconLeft"></slot>
    </span>
    <span v-if="!loading">{{ label }}</span>
    <img v-if="loading" src="@/assets/images/icons/loader-anim-white.svg" class="loader" alt="loading">
    </div>
    <router-link
        :to="to"
        v-else
        class="v-button"
        :class="[styleLink,{
      'v-button_disabled': disabled,
      'v-button_auto-size': autoSize,
      'v-button_text-btn': textBtn,
    }]"
        :style="{
      border: borderColor ? `1px solid ${borderBtn}` : 'none',
    }"
        :disabled="disabled"
    >
    <span class="v-button__icon" v-if="$slots.iconLeft">
      <slot name="iconLeft"></slot>
    </span>
        {{ label }}
    </router-link>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    link: {
        type: Boolean,
        default: false,
    },
    to: {
        type: String
    },
    label: {
        type: String,
        required: true,
    },
    disableHover: {
        type: Boolean,
        default: false,
    },
    borderColor: {
        type: String,
    },
    borderColorHover: {
        type: String,
    },
    autoSize: {
        type: Boolean,
        default: false,
    },
    textBtn: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    styleType: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    }
});
//Перечень цветов primary secondary grey red red-secondary

const styleBtn = computed(() => {
  let style = [`${props.styleType}`]
  if (props.disabled) {
    style.push(`${props.styleType}_disabled`)
  }
  if (!props.disabled) {
    style.push(`${props.styleType}_click-effect`)
  }
  return style
})

const styleLink = computed(() => {
  let style = [`${props.styleType}`]
  if (props.disabled) {
    style.push(`${props.styleType}_disabled`)
  }
  return style
})

const borderBtn = ref(props.borderColor);



</script>

<style scoped lang="scss">
.v-button {
    height: 48px;
    padding: 0 7.5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: none;
    text-align: center;
    text-decoration: none;
    transition: 0.3s;
    border: none;
    outline: none;

    &_auto-size {
        height: auto;
        padding: 0;
    }

    &_text-btn {
        height: auto;
        padding: 0;
        background-color: transparent !important;
        font-size: 14px;
        font-weight: 400;
    }

    &__icon {
        margin-right: 4px;
        margin-top: 3px;
    }
}

.primary {
  background-color: $primary-main;
  color: $primary-text;
  &:hover {
    background-color: $primary-hover !important;
  }

  &_click-effect:active {
    background-color: $primary-pressed !important;
  }

  &_disabled {
    background-color: $disabled !important;
    color: $disabled-text !important;
    cursor: no-drop;
  }
}

.secondary {
  background-color: $secondary-main;
  color: $secondary-text;
  &:hover {
    background-color: $secondary-hover !important;
  }

  &_click-effect:active {
    background-color: $secondary-pressed !important;
  }

  &_disabled {
    background-color: $disabled !important;
    color: $disabled-text !important;
    cursor: no-drop;
  }
}

.grey {
  background-color: $grey-main;
  color: $grey-text;
  &:hover {
    background-color: $grey-hover !important;
  }

  &_click-effect:active {
    background-color: $grey-pressed !important;
  }

  &_disabled {
    background-color: $disabled !important;
    color: $disabled-text !important;
    cursor: no-drop;
  }
}

.red {
  background-color: $red-main;
  color: $red-text;
  &:hover {
    background-color: $red-hover !important;
  }

  &_click-effect:active {
    background-color: $red-pressed !important;
  }

  &_disabled {
    background-color: $disabled !important;
    color: $disabled-text !important;
    cursor: no-drop;
  }
}

.red-secondary {
  background-color: $red-secondary-main;
  color: $red-secondary-text;
  &:hover {
    background-color: $red-secondary-hover !important;
  }

  &_click-effect:active {
    background-color: $red-secondary-pressed !important;
  }

  &_disabled {
    background-color: $disabled !important;
    color: $disabled-text !important;
    cursor: no-drop;
  }
}

.loader {
  width: 32px;
  height: 32px;
  transform: scaleX(-1);
}

</style>
