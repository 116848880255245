<template>
    <div class="v-search-input">
        <VCustomInput
            :disabled="disabled"
            :placeholder="placeholder"
            :error="error"
            v-model="inputVal"
            class="v-search-input__inp"
            @focus="showItems = true"
            @blur="blur"
        />

        <div v-if="showItems && itemsList.length" class="v-search-input__items">
            <div
                v-for="(item, index) in itemsList"
                :key="index"
                @click="inputVal = item"
                class="v-search-input__item">{{ item }}
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    modelValue: [String, Number],
    placeholder: {
        type: String
    },
    error: {
        type: Boolean,
        default: false
    },
    results: {
        type: Array,
        default: []
    },
    disabled: {
        type: Boolean,
        default: false
    },
})

const emit = defineEmits(["update:modelValue", "blur"]);
const inputVal = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const showItems = ref(false);

const itemsList = computed(() => {
    return props.results.filter(item => item?.toUpperCase().indexOf(inputVal.value ? inputVal.value.toUpperCase() : '') >= 0)
})

const blur = () => {
    setTimeout(() => {
        showItems.value = false
    }, 300)
    setTimeout(() => {
        emit('blur')
    }, 400)
}
</script>

<style scoped lang="scss">
.v-search-input {
    position: relative;

    &__inp {
        width: 100%;
    }

    &__items {
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        max-height: 300px;
        padding: 8px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid $light-gray-1;
        box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.12);
        z-index: 2;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    &__item {
        width: 100%;
        height: 40px;
        padding-left: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        user-select: none;
        transition: .1s;

        &:hover {
            background-color: $light-gray-1;
        }
    }
}
</style>
