<template>
  <div class="accordion">
    <div class="accordion-item">
      <div
          class="accordion-header"
          @click="toggleAccordion"
      >
        <slot name="title">
          {{ title }}
        </slot>
        <img v-if="isOpen" src="@/assets/images/icons/arrow-top.svg" height="26" width="26" alt="arrow">
        <img v-else src="@/assets/images/icons/arrow-down.svg" height="26" width="26" alt="arrow">
      </div>
      <div
          v-if="isOpen"
          class="accordion-content"
      >
        <slot/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";

defineProps({
  documents: Array,
  extendedFormId: Number,
  title: {
    type: String,
    default: 'Title'
  },
})

const isOpen = ref(false);

const toggleAccordion = (index) => {
  isOpen.value = !isOpen.value
};

</script>

<style scoped>
.accordion {
  width: 100%;
  margin: 0 auto;
}

.accordion-item {
  border-radius: 4px;
}

.accordion-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  background-color: #fff;
}
</style>