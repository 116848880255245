import axios from "axios";
import store from "../store";
import router from "../router";
import eventBus from "@/services/eventBus";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

api.interceptors.request.use(
  async (config) => {
    // eventBus.emit('offline')

    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    if (store.getters["auth/token"]) {
      config.headers["Authorization"] = `Bearer ${store.getters["auth/token"]}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.push("/authorization");
    } else if (error.response.status !== 400) {
      console.log("error.response", error.response);
      if (error.response.data.message) {
        eventBus.emit("errorRequest", {
          title: "Ошибка",
          description: error.response.data.message,
        });
      } else if (error.response.data.error_message) {
        eventBus.emit("errorRequest", {
          title: "Ошибка",
          description: error.response.data.error_message,
        });
      } else {
        eventBus.emit("errorRequest");
      }
    } else if(error.response.status === 400) {
      eventBus.emit("errorRequest", {
        title: "Ошибка",
        description: error.response.data.error_message,
      });
    }
    return Promise.reject(error);
  }
);

export default api;
